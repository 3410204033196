<template>
  <div>
    <div>
      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12 w-100">
        <b-col
          cols="12"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
    </div>
    <!-- Error page-->
    <div class="misc-wrapper">

      <div class="misc-inner p-2 p-sm-3">
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          class="img"
          alt="img"
        />
        <div class="w-100 text-center mt-2">
          <h2 class="mb-1 title">
            {{ $t('register.textThanksForRegistration') }}
          </h2>
          <p class="mb-2 des">
            {{ $t('home.contentNoticeSendEmail') }} <strong>{{ decodeURIComponent(email) }}</strong>, {{ $t('home.textPleaseCheckYourInbox') }}.
          </p>

          <btn-loading
            v-if="!hasIframe"
            variant-convert="flat-primary"
            class="mb-2 btn-sm-block"
            style="text-decoration: underline"
            @click="handleGoToUrl"
          >
            {{ textSubmit }}
          </btn-loading>
        </div>
      </div>
    </div>
  <!-- / Error page-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BImg, BRow, BCol,
} from 'bootstrap-vue'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import store from '@/store/index'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BtnLoading,
    BrandLogo,
    BImg,
    BRow,
    BCol,
  },
  mixins: [envMixin],
  data() {
    return {
      downImg: require('@/assets/images/pages/auth/email-verify.png'),
      email: '',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-404.png')
        return this.downImg
      }

      if (this.isEmediaPlatform) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/auth/email-verify-emedia.png')
        return this.downImg
      }

      return this.downImg
    },

    hasIframe() {
      return window !== window.top
    },

    textSubmit() {
      if (this.hasIframe) {
        return this.$t('register.btnSwitchHomePage')
      }

      return this.$t('register.btnBackToLogin')
    },
  },

  mounted() {
    if (this.$route.query && this.$route.query.email) {
      this.email = this.$route.query.email
    }
  },

  methods: {
    handleGoToUrl() {
      if (this.hasIframe) {
        window.top.location.href = `${process.env.VUE_APP_SITE_URL}/login`
      } else {
        this.$router.push('/')
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .title {
    font-size: 34px;
  }

  .des {
    font-size: 20px;
    line-height: 30px;
  }
</style>
